<template>
  <div class="hs-tooltip overflow-hidden [--placement:right]">
    <p ref="textElement" :class="['hs-tooltip-toggle truncate', props.class]" @mouseenter="showTooltip" @mouseleave="hideTooltip">
      {{ props.content }}
    </p>
    <span
      v-if="isTruncated"
      class="hs-tooltip-content transition-opacity inline-block absolute z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
      :class="{ 'opacity-100 visible': isVisible, 'opacity-0 invisible': !isVisible }"
      role="tooltip"
    >
      {{ props.content }}
    </span>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from 'vue';

  const props = defineProps<{
    content: string;
    class?: string;
  }>();

  const isVisible = ref(false);
  const isTruncated = ref(false);
  const textElement = ref<HTMLElement | null>(null);

  const checkIfTruncated = () => {
    if (textElement.value) {
      isTruncated.value = textElement.value.scrollWidth > textElement.value.clientWidth;
    }
  };

  const showTooltip = () => {
    if (isTruncated.value) {
      isVisible.value = true;
    }
  };

  const hideTooltip = () => {
    isVisible.value = false;
  };

  onMounted(() => {
    checkIfTruncated();
  });

  watch(() => props.content, checkIfTruncated);
</script>
